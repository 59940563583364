import React from 'react';
import {Layout} from '../components/Layout';
import HeroIllustration from '../assets/svg/hero-illustration.svg';
import {Link} from 'gatsby';
import WhoWeAreIllustration from '../assets/svg/who-we-are-illustration.svg';
import HowToHelpIllustration from '../assets/svg/how-to-help-illustration.svg';
import MailIcon from '../assets/svg/mail-icon.svg';
import SocialIcons from '../assets/svg/social-icons.svg';
import MediaAboutUsIllustration from '../assets/svg/media-about-us-illustration.svg';

function Index() {
  return (
    <Layout
      seo={{
        title: 'Soloma cats',
        description: `Charitable foundation that helps defenders, rescuers and civilians. Focus on tactical medicine and demining. We create projects to bring Ukraine closer to victory.`,
      }}
      language={'en'}
    >
      <section className="hero-section">
        <div className="content">
          <h1 className="hero-section__heading">
            HI, WE ARE <br className="mobile-only" /> SOLOMA CATS
          </h1>
          <HeroIllustration className="hero__illustration" />
          <p className="hero-section__lead">
            Charitable foundation that helps defenders, rescuers and civilians. Focus on tactical
            medicine and demining. We create projects to bring Ukraine closer to victory.
          </p>
          <Link to="#how-to-help" className="button button_black hero-section__button">
            Donate
          </Link>
        </div>
      </section>
      <section className="who-we-are" id="who-we-are">
        <div className="content">
          <h2 className="who-we-are__heading">During the first 50 days of the war:</h2>
          <div className="who-we-are__counter-list">
            <div className="who-we-are__counter-list-item">
              <div className="who-we-are__counter">1920</div>
              <div className="who-we-are__counter-placeholder">requests for help were done </div>
            </div>
            <div className="who-we-are__counter-list-item">
              <div className="who-we-are__counter">737</div>
              <div className="who-we-are__counter-placeholder">
                food and hygiene sets were distributed to people in need
              </div>
            </div>
            <div className="who-we-are__counter-list-item">
              <div className="who-we-are__counter">250</div>
              <div className="who-we-are__counter-placeholder">
                people joined the volunteering community{' '}
              </div>
            </div>
            <div className="who-we-are__counter-list-item">
              <div className="who-we-are__counter">465</div>
              <div className="who-we-are__counter-placeholder">
                defenders were trained in tactical medicine by our instructors
              </div>
            </div>
            <div className="who-we-are__counter-list-item">
              <div className="who-we-are__counter">106</div>
              <div className="who-we-are__counter-placeholder">
                first aid kits were handed over to the defenders{' '}
              </div>
            </div>
          </div>
          <div className="who-we-are__illustration-and-activity">
            <WhoWeAreIllustration className="who-we-are__illustration" />
            <div className="who-we-are__activity-list">
              <p className="who-we-are__activity-paragraph">
                Handed over humanitarian aid and equipment for defenders and civilians for more than
              </p>
              <h3 className="who-we-are__activity-heading">1 367 292 USD</h3>
              <p className="who-we-are__activity-paragraph">
                Collected thanks to the charitable donations
              </p>
              <h3 className="who-we-are__activity-heading">198 225 USD</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="how-to-help" id="how-to-help">
        <div className="content">
          <h2 className="how-to-help__heading">How can I help?</h2>
          <HowToHelpIllustration className="how-to-help__illustration" />
          <div className="how-to-help__columns">
            <p className="how-to-help__donate-description how-to-help__item">Donate:</p>
            <p className="how-to-help__donate-addresses how-to-help__item">
              IBAN CO Soloma Cats UAH: <br />
              UA283052990000026003045019432 <br />
              IBAN CO Soloma Cats USD: <br />
              UA593052990000026002025031282 <br />
              Monobank USD: 4441114453109090
              <br />
              Monobank EUR: 5168745013080053
              <br />
              PayPal: ae.dexter26@gmail.com
            </p>
            <p className="how-to-help__donate-addresses-owner how-to-help__item">
              The owner of the accounts is Andrii Yerofeev
            </p>
            <p className="how-to-help__phone-description how-to-help__item">
              If you want to collaborate, help our charitable foundation or you know other ways how
              to speed up our victory, feel free to let us know by email:
            </p>
            <div className="how-to-help__phone-container how-to-help__item">
              <div className="how-to-help__phone-text">kitty.soloma@gmail.com</div>
              <a className="how-to-help__phone-button-link" href="mailto:kitty.soloma@gmail.com">
                <MailIcon className="how-to-help__mail-icon" />
              </a>
            </div>

            <p className="how-to-help__crypto-description how-to-help__item">
              Send cryptocurrency:
            </p>
            <div className="how-to-help__crypto-addresses how-to-help__item">
              Ethereum: <br />
              <span className="how-to-help__crypto-address">
                0x1f4c09CE996b14d361393e73Bd45C001469e5109
              </span>{' '}
              <br />
              Bitcoin: <br />
              <span className="how-to-help__crypto-address">
                bc1q0rg3pfheqzfpe6zzzj7gzx7xr35y9yf3neujp7
              </span>{' '}
              <br />
              USDT (Tether ERC20): <br />
              <span className="how-to-help__crypto-address">
                0x1f4c09CE996b14d361393e73Bd45C001469e5109
              </span>{' '}
              <br />
            </div>
            <p className="how-to-help__share-description how-to-help__item">
              Share our posts and tell your friends about us
            </p>

            <div className="how-to-help__subscribe-buttons-container how-to-help__item">
              <SocialIcons className="how-to-help__subscribe-buttons" />
              <a
                className="how-to-help__subscribe-link how-to-help__subscribe-link_telegram"
                href="https://t.me/kitty_soloma"
                target="_blank"
                rel="noreferrer"
              />
              <a
                className="how-to-help__subscribe-link how-to-help__subscribe-link_facebook"
                href="https://www.facebook.com/kittysoloma"
                target="_blank"
                rel="noreferrer"
              />
              <a
                className="how-to-help__subscribe-link how-to-help__subscribe-link_instagram"
                href="https://www.instagram.com/kitty_soloma"
                target="_blank"
                rel="noreferrer"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="media-about-us">
        <div className="content">
          <h2 className="media-about-us__heading">
            Media <br />
            about us
          </h2>
          <MediaAboutUsIllustration className="media-about-us__illustration" />
          <div className="media-about-us__news-container">
            <div className="media-about-us__post">
              <h3 className="media-about-us__post-heading">Ukrayinska Pravda</h3>
              <p className="media-about-us__post-lead">
                "You are not a woman here, because the war has no gender": Marharyta Rivchachenko on
                service in the defense and assistance to the army
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://life-pravda-com-ua.translate.goog/society/2022/03/8/247712/?_x_tr_sl=uk&_x_tr_tl=en"
                className="media-about-us__post-link"
              >
                read more
              </a>
            </div>
            <div className="media-about-us__post">
              <h3 className="media-about-us__post-heading">Hromadske</h3>
              <p className="media-about-us__post-lead">
                Resigned and went to the front. Where are the deputies of the Ukrainian Parliament
                doing today?
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://hromadske-ua.translate.goog/posts/poklav-mandat-pishov-na-front-de-nini-narodni-deputati?_x_tr_sl=uk&_x_tr_tl=en"
                className="media-about-us__post-link"
              >
                read more
              </a>
            </div>
            <div className="media-about-us__post">
              <h3 className="media-about-us__post-heading">Hmarochos</h3>
              <p className="media-about-us__post-lead">
                Volunteers «Soloma Cats» created a bicycle group to deliver food and medicine
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://hmarochos-kiev-ua.translate.goog/2022/03/08/volontery-solomyanski-kotyky-stvoryly-velozagin-dlya-dostavky-produktiv-i-likiv/?_x_tr_sl=uk&_x_tr_tl=en&_x_tr_hl=ru&_x_tr_pto=wapp"
                className="media-about-us__post-link"
              >
                read more
              </a>
            </div>
            <div className="media-about-us__post">
              <h3 className="media-about-us__post-heading">BZH Life</h3>
              <p className="media-about-us__post-lead">
                Activists have developed a plan to protect the stained glass windows of the Kyiv
                funicular: how to help the initiative
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://bzh-life.translate.goog/ua/gorod/akivisti-rozrobili-plan-zahistu-vitrazhiv-kiyivskogo-funikuleru-yak-dopomogti-inicziativi/?_x_tr_sl=uk&_x_tr_tl=en&_x_tr_hl=ru&_x_tr_pto=wapp"
                className="media-about-us__post-link"
              >
                read more
              </a>
            </div>
            <div className="media-about-us__post">
              <h3 className="media-about-us__post-heading">Voice of Ukraine</h3>
              <p className="media-about-us__post-lead">«Soloma Cats»: a step to victory.</p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/GolosUkrainy/posts/2992019401128571"
                className="media-about-us__post-link"
              >
                read more
              </a>
            </div>
            <div className="media-about-us__post">
              <h3 className="media-about-us__post-heading">Hmarochos</h3>
              <p className="media-about-us__post-lead">
                They save lives, rescue from hot spots, and deliver aid. What do Kyiv urban
                activists do during the war?
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://hmarochos-kiev-ua.translate.goog/2022/03/30/ryatuyut-zhyttya-vyvozyat-z-garyachyh-tochok-dostavlyayut-dopomogu-shho-roblyat-kyyivski-urbanisty-pid-chas-vijny/?_x_tr_sl=uk&_x_tr_tl=en&_x_tr_hl=ru&_x_tr_pto=wapp"
                className="media-about-us__post-link"
              >
                read more
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Index;
